import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Confetti from "react-dom-confetti";

import { Layout } from "../components";

const config = {
  angle: 90,
  spread: 100,
  startVelocity: 60,
  elementCount: 150,
  dragFriction: 0.1,
  duration: 2500,
  stagger: 0,
  width: "7px",
  height: "10px",
  colors: [
    "#E68F17",
    "#FAB005",
    "#FA5252",
    "#E64980",
    "#BE4BDB",
    "#0B7285",
    "#15AABF",
    "#EE1233",
    "#40C057",
  ],
};

const BriefWebsiteThankYou = ({ pageContext: { slug } }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowConfetti(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Layout
      seo={{
        title: "Brief strona internetowa potwierdzenie",
        href: slug,
        lang: "pl",
        noIndex: true,
      }}
    >
      <div className="page_thanks">
        <img
          className="img-fluid"
          src={require("../assets/img/brief_thank_you.png")}
          alt=""
        />
        <p className="desc">
          Zapoznamy się z Twoimi oczekiwaniami najszybciej jak to możliwe,
          dobierając właściwe narzędzia do realizacji celu, który został nam
          postawiony w wypełnionym briefie. Do usłyszenia!
        </p>
        <p>
          <Link to="/" className="btn_custom">
            Strona główna
          </Link>
        </p>
        <Confetti active={showConfetti} config={config} className="confetti" />
      </div>
    </Layout>
  );
};

export default BriefWebsiteThankYou;
